// src/router/index.js

import Vue from 'vue'
import VueRouter from 'vue-router'

//our layouts
import Layout1 from '../layouts/Layout1.vue'
import Layout2 from '../layouts/Layout2.vue'
import DefaultLayout from '../layouts/DefaultLayout.vue'

//pages to use
import LoginSuccess from '@/views/LoginSuccess.vue'
import AuthorizationFailed from '@/views/AuthorizationFailed.vue'
import AdminDashboard from '@/views/admin/AdminDashboard.vue'
import DesktopMessage from '@/views/DesktopMessage.vue'
import DepositSuccess from '@/views/DepositSuccess.vue'
import DepositError from '@/views/DepositError.vue'
import store from '../store' // Import your Vuex store module

Vue.use(VueRouter)

const routes = [
  {
    path: '/desktop-message',
    name: 'DesktopMessage',
    component: DesktopMessage
  },
  {
    path: '/forgot-password',
    component: DefaultLayout, // Use Layout1 for the home route
    children: [
      {
        path: '',
        component: () => import('../views/ForgotPassword.vue'),
        meta: {
          title: 'Forgot Password' // Set the title for the home page
        }
      }
    ]
  },
  {
    path: '/login',
    component: DefaultLayout, // Use Layout1 for the home route
    children: [
      {
        path: '',
        component: () => import('../views/Login.vue'),
        meta: {
          title: 'Login' // Set the title for the home page
        }
      }
    ]
  },
  {
    path: '/register',
    component: DefaultLayout, // Use Layout1 for the home route
    children: [
      {
        path: '',
        component: () => import('../views/Register.vue'),
        meta: {
          title: 'Register' // Set the title for the home page
        }
      }
    ]
  },
  {
    path: '/create-new-password',
    component: DefaultLayout, // Use Layout1 for the home route
    children: [
      {
        path: '',
        component: () => import('../views/CreateNewPassword.vue'),
        meta: {
          title: 'Create New Password' // Set the title for the home page
        }
      }
    ]
  },
  {
    path: '/',
    component: DefaultLayout, // Use Layout1 for the home route
    children: [
      {
        path: '',
        component: () => import('../views/StarterPage.vue'),
        meta: {
          title: 'Home' // Set the title for the home page
          //requiresAuth: true
        }
      }
    ]
  },
  {
    path: '/home',
    component: Layout1, // Use Layout1 for the home route
    children: [
      {
        path: '',
        component: () => import('../views/Home.vue'),
        meta: {
          title: 'Home', // Set the title for the home page
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: '/myevents',
    component: Layout1, // Use Layout1 for the home route
    children: [
      {
        path: '',
        component: () => import('../views/MyEvents.vue'),
        meta: {
          title: 'My Events', // Set the title for the home page
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: '/events/:uniqueRef',
    component: Layout2, // Use Layout1 for the home route
    children: [
      {
        name: 'EventDetails',
        path: '',
        component: () => import('../views/EventRoom.vue'),
        meta: {
          title: 'Event Room', // Set the title for the home page
          requiresAuth: true
        }
      }
    ]
  },
  { 
    path: '/profile',
    
    component: Layout2, // Use Layout1 for the home route
    children: [
      {
        path: '',
        component: () => import('../views/Profile.vue'),
        meta: {
          title: 'Profile', // Set the title for the home page
          requiresAuth: true
        }
      }
    ]
  },
  { 
    path: '/createevent',
    
    component: Layout2, // Use Layout1 for the home route
    children: [
      {
        path: '',
        component: () => import('../views/CreateEvent.vue'),
        meta: {
          title: 'Create Event', // Set the title for the home page
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: '/earnings',
    component: Layout1, // Use Layout1 for the home route
    children: [
      {
        path: '',
        component: () => import('../views/Earnings.vue'),
        meta: {
          title: 'Earnings', // Set the title for the home page
          requiresAuth: true
        }
      }
    ]
  },

  {
    path: '/withdrawal-request',
    component: Layout2, // Use Layout1 for the home route
    children: [
      {
        path: '',
        component: () => import('../views/admin/WithdrawalRequest.vue'),
        meta: {
          title: 'Withdrawal Request', // Set the title for the home page
          requiresAuth: true
        }
      }
    ]
  },
  
  { 
    path: '/marketplace',
    
    component: Layout2, // Use Layout1 for the home route
    children: [
      {
        path: '',
        component: () => import('../views/Marketplace.vue'),
        meta: {
          title: 'Marketplace', // Set the title for the home page
          requiresAuth: true
        }
      }
    ]
  },
  { 
    path: '/login-success', 
    component: LoginSuccess 
  },
  { 
    path: '/password-reset-success',
    component: DefaultLayout, // Use Layout1 for the home route
    children: [
      {
        path: '',
        component: () => import('../views/PasswordResetSuccess.vue'),
        meta: {
          title: 'Password Reset Success' // Set the title for the home page
        }
      }
    ]
  },
  { 
    path: '/password-reset-failed',
    component: DefaultLayout, // Use Layout1 for the home route
    children: [
      {
        path: '',
        component: () => import('../views/PasswordResetFailed.vue'),
        meta: {
          title: 'Password Reset Failed' // Set the title for the home page
        }
      }
    ]
  },
  {
    path: '/authorization-failed',
    name: 'AuthorizationFailed',
    component: AuthorizationFailed
  },
  {
    path: '/user-ad-check',
    name: 'AdminDashboard',
    component: AdminDashboard
  },
  
  {
    path: '/deposit/success',
    name: 'DepositSuccess',
    component: DepositSuccess,
    // Add the beforeEnter guard to check conditions
    beforeEnter: (to, from, next) => {
      // Check if the route was redirected from the server-side
      if (to.query.redirectedFromServer === 'true') {
        
        next()

        return

      } else {
        // Redirect to home or another appropriate route
        
        next('/')

        return

      }
    },
    beforeRouteLeave: (to, from, next) => {
      // Ensure that the user cannot return to this page
      next({ ...to, replace: true })

      return
    },
    beforeRouteEnter: (to, from, next) => {
      // Prevent re-entering the page
      if (from.name === 'DepositSuccess') {
        
        next(false)

        return

      } else {

        next()

        return
      }
    }
  },
  {
    path: '/deposit/error',
    name: 'DepositError',
    component: DepositError,
    // Add the beforeEnter guard to check conditions
    beforeEnter: (to, from, next) => {
      // Check if the route was redirected from the server-side
      if (to.query.redirectedFromServer === 'true') {
        
        next()

        return

      } else {
        // Redirect to home or another appropriate route
        next('/')

        return

      }
    },
    beforeRouteLeave: (to, from, next) => {
      // Ensure that the user cannot return to this page
      next({ ...to, replace: true })

      return
    },
    beforeRouteEnter: (to, from, next) => {
      // Prevent re-entering the page
      if (from.name === 'DepositError') {
        
        next(false)

        return

      } else {

        next()

        return
      }
    }
  }
]

const router = new VueRouter({
  mode: 'history', // Add this line to enable history mode
  routes
})

// Global navigation guard
router.beforeEach((to, from, next) => {
  const isLoggedIn = store.getters['auth/isLoggedIn']

  store.commit('loading/setLoading', false, { root: true })

  ////console.log('is loggedIn at router is ', isLoggedIn)

  if ((to.path === '/login' || to.path === '/authorization-failed' || to.path === '/register' ) && isLoggedIn) {
    // If the user is already logged in and tries to access the login route,
    // redirect them to another route (e.g., the home page)
    next({ path: '/' })

    return

  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    // Check if the route requires authentication
    if (!isLoggedIn) {
      // If not logged in, redirect to the login page
      next({ path: '/login' })

      return

    } else {
      next() // Proceed to the route

      return

    }
  } else {
    next() // Proceed to the route

    return

  }
})

export default router