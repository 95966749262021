<!-- src/components/BottomNavigation.vue -->
<template>
  <v-bottom-navigation class="bottom-nav">
    <v-btn :to="homeRoute" :class="{ 'active': activeRoute === homeRoute }"> Home <v-icon>mdi-home</v-icon></v-btn>
    <v-btn :to="earningRoute" :class="{ 'active': activeRoute === earningRoute }"> Earnings <v-icon>mdi-food</v-icon></v-btn>
    <v-btn :to="createEventRoute" :class="{ 'active': activeRoute === createEventRoute }"> Create Event <v-icon>mdi-plus</v-icon></v-btn>
    <v-btn :to="marketplaceRoute" :class="{ 'active': activeRoute === marketplaceRoute }"> Market Place <v-icon>mdi-shopping</v-icon></v-btn>
    <!-- Add more buttons as needed -->
  </v-bottom-navigation>
</template>

<script>
export default {
  name: 'BottomNavigation',
  props: {
    activeRoute: {
      type: String,
      required: true
    }
  },
  computed: {
    homeRoute() {
      return '/home'
    },
    profileRoute() {
      return '/profile'
    },
    marketplaceRoute() {
      return '/marketplace'
    },
    earningRoute() {
      return '/earnings'
    },
    createEventRoute() {
      return '/createevent'
    }
    // Add more computed properties for other routes as needed
  }
}
</script>

<style scoped>
/* Add any specific styling for the bottom navigation */
.bottom-nav {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.active {
  background-color: #ecb68a !important; /* Set your desired background color */
  color: #000; /* Set your desired text color for the active item */
}
</style>
