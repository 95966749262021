<template>
  <div>
    <h1>Login Successful!</h1>
    <p>Redirecting...</p>
  </div>
</template>

<script>
export default {
  created() {
    // Call the handleGoogleCallback action when the component is created
    this.$store.dispatch('auth/handleGoogleCallback')
  }
}
</script>
