// src/store/modules/auth.js

// Import any necessary dependencies
// ...
//import router from '../../router' // Adjust the path based on your project structure

// store/modules/snackbar.js
const state = {
  snackbarStatus: false,
  snackbarMessage: '',
  snackbarVariant: 'black' // Default variant
}

const mutations = {
  SET_SNACKBAR_STATUS(state, status) {
    state.snackbarStatus = status
  },
  SET_SNACKBAR_MESSAGE(state, message) {
    state.snackbarMessage = message
  },
  SET_SNACKBAR_VARIANT(state, variant) {
    state.snackbarVariant = variant
  }
}

const actions = {
  showSnackbar({ commit, state }, { message, variant = 'info' }) {
    commit('SET_SNACKBAR_MESSAGE', message)
    commit('SET_SNACKBAR_VARIANT', variant)
    commit('SET_SNACKBAR_STATUS', true)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
