<template>
  <v-app style="background-color: white !important;">
    <!-- Top App Bar -->
    <v-app-bar app class="" style="">
      <v-app-bar-nav-icon style="" @click="toggleDrawer"></v-app-bar-nav-icon>
      <v-toolbar-title class="pl-0">{{ appName }} </v-toolbar-title>
    </v-app-bar>

    <!-- Side Navigation (Drawer) -->
    <v-navigation-drawer v-model="drawer" app>
      <!-- Wallet Balance Section -->
      <v-card class=" py-4 px-6 px-0" style="border-radius: 0px !important; background-color: #ecb68a !important;">
        <v-row>
          <v-col class="px-0 pt-2 pb-0">
            Hi, Welcome  
          </v-col>
          <v-col cols="12" class="text-h6 pt-0 pb-0 px-0">
            {{ userInfo.fullname }}
          </v-col>
        </v-row>
      </v-card>

      <!-- Navigation Items -->
      <v-list>
        <v-list-item v-for="(item, index) in filteredMenuItems" :key="index" @click="navigate(item.route)">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <button style="margin-left: 15px !important;" @click.prevent="logout">
        <v-icon>mdi-logout</v-icon> Logout
      </button>
    </v-navigation-drawer>

    <!-- Page Content -->
    <v-main>
      <v-container>
        <router-view />
      </v-container>
    </v-main>

    <!-- Bottom Navigation -->
    <BottomNavigation :activeRoute="$route.path"/>
  </v-app>
</template>

<script>
import BottomNavigation from '@/components/BottomNavigation.vue'

export default {
  name: 'Layout1',
  components: {
    BottomNavigation
  },
  data() {
    return {
      appName: 'Learnbread', 
      drawer: false,
      walletBalance: 1000, // Replace with actual wallet balance
      menuItems: [
        { text: 'Home', icon: 'mdi-home', route: '/home', loggedIn: true },
        { text: 'My Earnings', icon: 'mdi-food', route: '/earnings', loggedIn: true },
        { text: 'Marketplace', icon: 'mdi-shopping', route: '/marketplace', loggedIn: true },
        { text: 'My Events', icon: 'mdi-ticket', route: '/myevents', loggedIn: true },
        { text: 'About Us', icon: 'mdi-help-circle', route: '/', loggedIn: true }
        // Add more items as needed
      ]
    }
  },
  computed: {
    pageTitle() {
      // Get the current route's meta title or fallback to a default
      const routeMeta = this.$route.meta

      return routeMeta && routeMeta.title ? routeMeta.title : 'Learnbread'
    },
    filteredMenuItems() {
      // Filter menu items based on the loggedIn state
      return this.menuItems.filter((item) => {
        // Show the item if loggedIn is true or if it's a common item
        return this.loggedIn || !item.loggedIn
      })
    },
    loggedIn() {
      return this.$store.getters['auth/isLoggedIn']
    },
    userInfo() {
      return this.$store.getters['auth/userInfo']
    }
  },
  methods: {
    toggleDrawer() {
      this.drawer = !this.drawer
    },
    navigate(route) {
      // Navigate to the specified route
      if (route === '/logout') {
        this.logout()
      } else {
        this.$router.push(route)
        this.drawer = false // Close the drawer after navigation
      }
    },
    logout() {
      // Add your logout logic here
      // For example, clearing authentication token, resetting user state, etc.
      this.$store.dispatch('auth/logout')
      //this.loggedIn = false
      // Redirect to the login page or home page after logout
      //this.$router.push('/')
    }
  }
}
</script>

<style scoped>
/* Add any specific styling for Layout1 */
.bottom-nav {
  position: fixed;
  bottom: 0;
  width: 100%;
}
</style>
