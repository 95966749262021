// src/store/modules/auth.js

// Import any necessary dependencies
// ...
import router from '../../router' // Adjust the path based on your project structure

const state = {
  loggedIn: false,
  userInfo: {},
  token: '',
  errorMessage: '' // New state to hold error messages
}

// Load authentication state from localStorage if available
const savedToken = localStorage.getItem('authToken') 
const savedUserInfo = localStorage.getItem('userInfo') 
const savedLoggedIn = Boolean(localStorage.getItem('loggedIn') )

//console.log('our savedloggedin is ', savedLoggedIn)

if (savedToken && savedUserInfo && savedLoggedIn) {
  state.token = savedToken 
  //state.userInfo = JSON.parse(savedUserInfo)
  state.loggedIn = savedLoggedIn
  // Check if savedUserInfo is a non-empty string before parsing
  if (typeof savedUserInfo === 'string' && savedUserInfo.trim() !== '') {
    try {
      // Attempt to parse the JSON string
      state.userInfo = JSON.parse(savedUserInfo)
    } catch (error) {
      console.error('Error parsing userInfo:', error)
      console.warn('Invalid or empty userInfo found in localStorage')

      // Clear localStorage
      localStorage.removeItem('authToken')
      localStorage.removeItem('userInfo')
      localStorage.removeItem('loggedIn')

      // Refresh the page
      window.location.reload()
    }
  } else {
    console.warn('Invalid or empty userInfo found in localStorage')

    // Clear localStorage
    localStorage.removeItem('authToken')
    localStorage.removeItem('userInfo')
    localStorage.removeItem('loggedIn')

    // Refresh the page
    window.location.reload()
  }
}

const getters = { 
  isLoggedIn: (state) => state.loggedIn,
  userInfo: (state) => state.userInfo,
  token: (state) => state.token
}

const mutations = {
  setLoggedIn(state, value) {
    state.loggedIn = value

    localStorage.setItem('loggedIn', true) 

  },  
  setUserInfo(state, userInfo) {
    state.userInfo = userInfo 
    // Save userInfo to localStorage
    localStorage.setItem('userInfo', JSON.stringify(userInfo)) 
  },
  setToken(state, token) {
    state.token = token 
    // Save token to localStorage
    localStorage.setItem('authToken', token) 
  },
  setErrorMessage(state, message) {
    state.errorMessage = message
  }
}

const actions = {
  // Google login action
  async loginWithGoogle({ commit }) {
    try {
      // Redirect to the backend route for Google authentication
      // This might involve opening a new window or redirecting the user
      // Replace `${process.env.VUE_APP_BASE_SERVICE_URL_LOCAL}/api/auth/google' with your actual backend route
      window.location.href = `${process.env.VUE_APP_BASE_SERVICE_URL_LOCAL}/api/auth/google`
    } catch (error) {
      console.error('Google login error:', error)
    }
  },

  async forgotPassword({ commit }, credentials) {
    try {
      // Make your login API request here
      const response = await fetch(`${process.env.VUE_APP_BASE_SERVICE_URL_LOCAL}/api/auth/forgotpassword`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
      })

      if (response.ok) {
        //const userData = await response.json();
        const responseData = await response.json()

        return responseData
      } else {
        // Handle login error if needed
        const responseData = await response.json()

        //commit('setErrorMessage', responseData.error || 'An error occurred. Please try again.')

        return responseData
      }
    } catch (error) {
      console.error('Error during login:', error)

      return error
      // Handle login error if needed
    }
  },

  async resetPassword({ commit }, credentials) {
    try {
      // Make your login API request here
      const response = await fetch(`${process.env.VUE_APP_BASE_SERVICE_URL_LOCAL}/api/auth/createnewpassword`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
      })

      if (response.ok) {
        //const userData = await response.json();
        const responseData = await response.json()

        return responseData
      } else {
        // Handle login error if needed
        const responseData = await response.json()

        //commit('setErrorMessage', responseData.error || 'An error occurred. Please try again.')

        return responseData
      }
    } catch (error) {
      console.error('Error during login:', error)

      return error
      // Handle login error if needed
    }
  },

  async loginWithLearnbread({ commit }, credentials) {
    try { 
      // Make your login API request here
      //https://digi.omeife.ai/api/v1/user/login
      const response = await fetch(`${process.env.VUE_APP_BASE_SERVICE_URL_LOCAL}/api/auth/login`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
      })

      if (response.ok) {
        //const userData = await response.json();
        const { token, userProfile } = await response.json()

        // Process the token and user information
        commit('setLoggedIn', true)
        commit('setUserInfo', userProfile)
        commit('setToken', token)

        // Save to localStorage
        localStorage.setItem('authToken', token)
        localStorage.setItem('userInfo', JSON.stringify(userProfile))
        localStorage.setItem('loggedIn', true)
        commit('setErrorMessage', '')

        //console.log('our response user inform/token is ', userProfile + token)
        router.push('/home')
      } else {
        // Handle login error if needed
        const responseData = await response.json()

        commit('setErrorMessage', responseData.error || 'An error occurred. Please try again.')

        return responseData
      }
    } catch (error) {
      console.error('Error during login:', error)
      // Handle login error if needed
    }
  },

  async handleGoogleCallback({ commit }) {
    try {
      // Get the token from the URL query parameters
      const urlSearchParams = new URLSearchParams(window.location.search)
      const token = urlSearchParams.get('token')

      ////console.log('below is our token', token)

      if (token) {
        // Fetch user information from the profile endpoint
        const response = await fetch(`${process.env.VUE_APP_BASE_SERVICE_URL_LOCAL}/api/user/profile`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`
          }
        })

        if (response.ok) {
          const { token, userProfile } = await response.json()

          // Process the token and user information
          commit('setLoggedIn', true)
          commit('setUserInfo', userProfile)
          commit('setToken', token)

          // Save to localStorage
          localStorage.setItem('authToken', token)
          localStorage.setItem('userInfo', JSON.stringify(userProfile))
          localStorage.setItem('loggedIn', true)

          //console.log('our response user inform/token is ', userProfile + token)
          router.push('/home')
        } else {
          // Handle error response
          console.error('Error:', response.statusText)
  
          if (response.status === 401) {
            // Authorization error, redirect to the authorization failed page
            // Clear the state
            commit('setLoggedIn', false) 
            commit('setUserInfo', {}) 
            commit('setToken', '') 

            // Clear localStorage
            localStorage.removeItem('authToken') 
            localStorage.removeItem('userInfo')
            localStorage.removeItem('loggedIn')

            router.push('/authorization-failed')

          } else {
            // Redirect to a different route (e.g., login page)
            router.push('/')
          }
        }
      } else {
        console.error('Token not found in the URL parameters.')
      }
    } catch (error) {
      console.error('Error handling Google callback:', error)
      
      // Redirect to a different route (e.g., login page)
      router.push('/')
    }
  },

  // Logout action
  async logout({ commit, rootGetters }) {
    try {
      // Get the authentication token from the store
      const authToken = rootGetters['auth/token']
      const userInfo = rootGetters['auth/userInfo']

      // Call your API for deposit with the token in the headers
      const response = await fetch(`${process.env.VUE_APP_BASE_SERVICE_URL_LOCAL}/api/auth/logout`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        },
        body: JSON.stringify({ userProfile: userInfo })
      })

      if (response.ok) {
        // Clear the state
        commit('auth/setLoggedIn', false, { root: true })
        commit('auth/setUserInfo', {}, { root: true }) 
        commit('auth/setToken', '', { root: true }) 

        // Clear localStorage
        localStorage.removeItem('authToken') 
        localStorage.removeItem('userInfo') 
        localStorage.removeItem('loggedIn') 

        router.push('/login')

      } else {
        console.error('Error:', response.statusText)
  
        if (response.status === 401) {
          
          // Clear the state
          commit('auth/setLoggedIn', false, { root: true })
          commit('auth/setUserInfo', {}, { root: true }) 
          commit('auth/setToken', '', { root: true }) 
          
          // Clear localStorage
          localStorage.removeItem('authToken') 
          localStorage.removeItem('userInfo')
          localStorage.removeItem('loggedIn')

          // Authorization error, redirect to the authorization failed page
          router.push('/login')

        } else {
          // Redirect to a different route (e.g., login page)
          router.push('/')
        }
      }
      
    } catch (error) {
      console.error('Error submitting deposit:', error)
      // Handle the error
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}  