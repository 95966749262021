<!-- src/layouts/Layout2.vue -->
<template>
  <v-app style="background-color: white !important;">
    <!-- Top App Bar -->
    <v-app-bar app class="primary" style="background-color: #ecb68a !important;">
      <v-btn icon @click="goBack" class="">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title class="pl-0">{{ pageTitle }}</v-toolbar-title>
    </v-app-bar>

    <!-- Page Content -->
    <v-main>
      <v-container>
        <router-view />
      </v-container>
    </v-main>

    <!-- Bottom Navigation -->
    <BottomNavigation :activeRoute="$route.path"/>
  </v-app>
</template>

<script>
import BottomNavigation from '@/components/BottomNavigation.vue'

export default {
  name: 'Layout2',
  components: {
    BottomNavigation
  },
  data() {
    return {
      appName: 'Learnbread'
    }
  },
  computed: {
    pageTitle() {
      // Get the current route's meta title or fallback to a default
      const routeMeta = this.$route.meta

      return routeMeta && routeMeta.title ? routeMeta.title : this.appName
    }
  },
  methods: {
    goBack() {
      // Go back to the previous page
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
/* Add any specific styling for Layout2 */
.bottom-nav {
  position: fixed;
  bottom: 0;
  width: 100%;
}
</style>
