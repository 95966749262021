<!-- AuthorizationFailed.vue -->
<template>
  <div style="text-align: center !important; padding: 20px !important;">
    <h1>Session Timed Out </h1>
    <p>Your session has expired or you are not authorized. Please log in again.</p>
    <router-link to="/login">Go to Login again</router-link>
  </div>
</template>

<script>
export default {
  // Component logic
}
</script>

<style scoped>
/* Component styles */
</style>