<!-- components/Snackbar.vue -->
<template>
  <div class="text-center">
    <v-snackbar v-model="snackbarStatus" vertical :top="true" :color="snackbarVariant">
      <div class="text-subtitle-1 pb-2">{{ snackbarMessage }}</div>

      <template v-slot:actions>
        <v-btn color="secondary" variant="text" @click="closeSnackbar">Close</v-btn>
      </template>
    </v-snackbar>

    <!-- Snackbar for offline notification -->
    <v-snackbar v-model="offline" color="error" timeout="-1">
      You are offline. Please check your internet connection.
    </v-snackbar>

    <!-- Snackbar for online notification -->
    <v-snackbar v-model="online" color="success" timeout="3000">
      Network is back! You are online.
    </v-snackbar>
  </div>
</template>

<script>
import eventBus from '../eventBus'

export default {
  data() {
    return {
      offline: false,
      online: false // Added for online notification
      //snackbarStatus: false
    }
  },
  created() {
    // Listen for online/offline events using the event bus
    eventBus.$on('online', (isOnline) => {
      this.offline = !isOnline
      this.online = isOnline
    })
  },
  computed: {
    snackbarMessage() {
      return this.$store.state.snackbar.snackbarMessage
    },
    snackbarVariant() {
      return this.$store.state.snackbar.snackbarVariant
    },
    snackbarStatus: {
      get() {
        return this.$store.state.snackbar.snackbarStatus
      },
      set(value) {
        this.$store.commit('snackbar/SET_SNACKBAR_STATUS', value)
      }
    }
  },
  methods: {
    closeSnackbar() {
      //this.computedSnackbarStatus = false;
      this.$store.dispatch('snackbar/showSnackbar', {
        message: '' // Reset the message when closing
      })
    }
  }
}
</script>