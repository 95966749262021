import { render, staticRenderFns } from "./DepositSuccess.vue?vue&type=template&id=db3df506&scoped=true&"
import script from "./DepositSuccess.vue?vue&type=script&lang=js&"
export * from "./DepositSuccess.vue?vue&type=script&lang=js&"
import style0 from "./DepositSuccess.vue?vue&type=style&index=0&id=db3df506&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db3df506",
  null
  
)

export default component.exports