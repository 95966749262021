// src/views/DesktopMessage.vue
<template>
  <div>
    <h1>Desktop Version</h1>
    <p>This website is best viewed on mobile devices. Please switch to a mobile device for the optimal experience.</p>
  </div>
</template>

<script>
export default {
  name: 'DesktopMessage'
}
</script>

<style scoped>
/* Add your styling for the desktop message */
</style>