<template>
  <v-container class="deposit-success-container">
    <v-row align="center" justify="center">
      <v-col cols="12">
        <img src="/learnbread-primary-logo-removebg.png" alt="Learnbread Logo" class="logo" />
        <v-alert>
          <v-icon style="font-size: 50px !important;">mdi-check-circle-outline</v-icon>
          <h1>{{ messageTitle }}</h1>
          <p>{{ message }}</p>
        </v-alert>
        <v-btn @click="redirectToHome" color="primary">Go to Home</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      message: '',
      messageTitle: 'Deposit Successful.'
    }
  },
  created() {
    // Access the message from the URL parameters
    this.message = this.$route.query.message
    this.message = this.$route.query.messageTitle

    // Now you can use the message in your component
    //////console.log(message)
  },
  methods: {
    redirectToHome() {
      this.$router.push('/home')
    }
  }
}
</script>

<style scoped>
.deposit-success-container {
  text-align: center;
  margin: 100px auto;
}

.logo {
  width: 100px;
  height: auto;
  margin-bottom: 20px;
}
</style>
