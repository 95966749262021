// src/store/index.js

import Vue from 'vue'
import Vuex from 'vuex'
import AuthModule from './modules/auth'
import WalletModule from './modules/wallet'
import SnackBar from './modules/snackbar'
import LoadingModule from './modules/loading' 

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    auth: AuthModule,
    wallet: WalletModule,
    snackbar: SnackBar,
    loading: LoadingModule
    // Add other modules as your app grows
  }
})

export default store
