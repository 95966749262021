<template>
  <v-app>
    <div class="global-overlay"></div>
    <!-- Loading Overlay -->
    <v-overlay v-if="loading" class="custom-overlay">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-overlay>

    <router-view />
    <Snackbar />
  </v-app>
</template>

<script>
import config from './configs'
import Snackbar from '@/components/Snackbar.vue' // Adjust the path based on your project structure

/*
|---------------------------------------------------------------------
| Main Application Component
|---------------------------------------------------------------------
*/
export default {
  
  computed: {
    loading() {
      // Access the loading state from the Vuex store
      return this.$store.getters['loading/isLoading']
    }
  },
  components: {
    Snackbar
  },
  head: {
    link: [
      // adds config/icons into the html head tag
      ...config.icons.map((href) => ({ rel: 'stylesheet', href }))
    ]
  }
}
</script>

<style scoped>
  .global-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(96, 57, 19, 0.05); /* Adjust the last value for transparency */
    pointer-events: none; /* Allows interaction with elements behind the overlay */
    z-index: 9999; /* Adjust the z-index as needed */
  }

  .custom-overlay {
    background-color: rgb(255, 255, 255); /* Adjust the color and opacity as needed */
    z-index: 9999;
    color: white !important;
  }
</style>