// src/eventBus.js
import Vue from 'vue'

// Add the redirection logic
if (window.innerWidth >= 600000) {
  window.location.href = '/desktop-message'
}

const eventBus = new Vue()

export default eventBus