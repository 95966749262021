// src/store/modules/wallet.js

// Import any necessary dependencies
// ...
import router from '../../router' // Adjust the path based on your project structure

const state = {
  userBalance: null,
  token: ''
}

// Load authentication state from localStorage if available
const savedToken = localStorage.getItem('authToken') 
const savedUserInfo = localStorage.getItem('userInfo') 
const savedUserBalance = localStorage.getItem('userBalance') 

/* if (savedToken && savedUserInfo && savedUserBalance) {
  state.token = savedToken 
  state.userBalance = JSON.parse(savedUserBalance) 
} */

if (savedToken && savedUserInfo && savedUserBalance) {
  state.token = savedToken
  // Check if savedUserBalance is not undefined
  if (savedUserBalance !== 'undefined') {
    state.userBalance = JSON.parse(savedUserBalance)
  } else {
    // Handle the case where userBalance is undefined
    state.userBalance = 0/* Set default value or handle accordingly */
  }
}

const getters = {
  userBalance: (state) => state.userBalance,
  token: (state) => state.token
}

const mutations = {
  setUserBalance(state, userBalance) {
    state.userBalance = userBalance
    // Save userInfo to localStorage
    localStorage.setItem('userBalance', JSON.stringify(userBalance)) 
  }
}

const actions = {
  
  async checkEarnings({ commit, rootGetters }, sending_data) {
    try {
      //const userInfo = rootGetters['auth/userInfo']
      const authToken = rootGetters['auth/token']
      
      // Call your API for deposit with the token in the headers
      const response = await fetch(`${process.env.VUE_APP_BASE_SERVICE_URL_LOCAL}/api/user/earnings`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        },
        body: JSON.stringify(sending_data)
      })

      ////console.log('response is here')

      if (response.ok) {
        ////console.log('response is here')
        const data = await response.json()

        // Check if the response contains a new token
        if (data.token) {
          //authToken = data.token
          // Update the authToken in the auth module state
          commit('auth/setToken', data.token, { root: true })
        }

        commit('setUserBalance', data.balance)

        // Check if the response contains a new token
        return data
      } else {
        // Handle error response
        console.error('Error:', response.statusText)

        const result = await response.json()

        if (response.status === 401) {

          // Authorization error, redirect to the authorization failed page
          commit('auth/setLoggedIn', false, { root: true })
          commit('auth/setUserInfo', {}, { root: true }) 
          commit('auth/setToken', '', { root: true }) 

          // Clear localStorage
          localStorage.removeItem('authToken') 
          localStorage.removeItem('userInfo')
          localStorage.removeItem('loggedIn')

          router.push('/authorization-failed')
          
        } else {
          // Redirect to a different route (e.g., login page)
          //router.push('/')
          return result
        }
      }
    } catch (error) {
      console.error('Error fetching user balance:', error)
    }
  },

  async getPendingWithdrawalRequest({ commit, rootGetters }, sending_data) {
    try {
      //const userInfo = rootGetters['auth/userInfo']
      const authToken = rootGetters['auth/token']

      console.log('we are about to send o')
      
      // Call your API for deposit with the token in the headers
      const response = await fetch(`${process.env.VUE_APP_BASE_SERVICE_URL_LOCAL}/api/user/pending-withdrawals`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        },
        body: JSON.stringify(sending_data)
      })

      ////console.log('response is here')

      if (response.ok) {
        ////console.log('response is here')
        const data = await response.json()

        console.log('our response is ', data)

        // Check if the response contains a new token
        if (data.token) {
          //authToken = data.token
          // Update the authToken in the auth module state
          commit('auth/setToken', data.token, { root: true })
        }

        return data

      } else {
        // Handle error response
        console.error('Error:', response.statusText)

        const result = await response.json()

        if (response.status === 401) {

          // Authorization error, redirect to the authorization failed page
          commit('auth/setLoggedIn', false, { root: true })
          commit('auth/setUserInfo', {}, { root: true }) 
          commit('auth/setToken', '', { root: true }) 

          // Clear localStorage
          localStorage.removeItem('authToken') 
          localStorage.removeItem('userInfo')
          localStorage.removeItem('loggedIn')

          router.push('/authorization-failed')
          
        } else {
          // Redirect to a different route (e.g., login page)
          //router.push('/')
          return result
        }
      }
    } catch (error) {
      console.error('Error fetching user balance:', error)
    }
  },

  async sellUserBread({ commit, rootGetters }, sending_data) {
    try {
      //const userInfo = rootGetters['auth/userInfo']
      const authToken = rootGetters['auth/token']

      console.log('we are about to send o')
      
      // Call your API for deposit with the token in the headers
      const response = await fetch(`${process.env.VUE_APP_BASE_SERVICE_URL_LOCAL}/api/user/process-withdrawal`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        },
        body: JSON.stringify(sending_data)
      })

      ////console.log('response is here')

      if (response.ok) {
        ////console.log('response is here')
        const data = await response.json()

        console.log('our response is ', data)

        // Check if the response contains a new token
        if (data.token) {
          //authToken = data.token
          // Update the authToken in the auth module state
          
          commit('auth/setToken', data.token, { root: true })

        }

        return data
      } else {
        // Handle error response
        console.error('Error:', response.statusText)

        const data = await response.json()

        if (response.status === 401) {

          // Authorization error, redirect to the authorization failed page
          commit('auth/setLoggedIn', false, { root: true })
          commit('auth/setUserInfo', {}, { root: true }) 
          commit('auth/setToken', '', { root: true }) 

          // Clear localStorage
          localStorage.removeItem('authToken') 
          localStorage.removeItem('userInfo')
          localStorage.removeItem('loggedIn')

          router.push('/authorization-failed')
          
        } else {
          // Redirect to a different route (e.g., login page)
          //router.push('/')
          console.log('We are not 401 o', data)

          return data
        }
      }
    } catch (error) {
      console.error('Error fetching user balance:', error)
    }
  },

  async cancelWithdrawalRequest({ commit, rootGetters }, sending_data) {
    try {
      const authToken = rootGetters['auth/token']

      console.log('we are about to send o')
      
      const response = await fetch(`${process.env.VUE_APP_BASE_SERVICE_URL_LOCAL}/api/user/cancel-withdrawal`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        },
        body: JSON.stringify(sending_data)
      })

      ////console.log('response is here')

      if (response.ok) {
        ////console.log('response is here')
        const data = await response.json()

        console.log('our response is ', data)

        if (data.token) {
          //authToken = data.token
          // Update the authToken in the auth module state
          
          commit('auth/setToken', data.token, { root: true })

        }

        return data
      } else {
        // Handle error response
        console.error('Error:', response.statusText)

        const data = await response.json()

        if (response.status === 401) {

          // Authorization error, redirect to the authorization failed page
          commit('auth/setLoggedIn', false, { root: true })
          commit('auth/setUserInfo', {}, { root: true }) 
          commit('auth/setToken', '', { root: true }) 

          // Clear localStorage
          localStorage.removeItem('authToken') 
          localStorage.removeItem('userInfo')
          localStorage.removeItem('loggedIn')

          router.push('/authorization-failed')
          
        } else {
          // Redirect to a different route (e.g., login page)
          //router.push('/')
          console.log('We are not 401 o', data)

          return data
        }
      }
    } catch (error) {
      console.error('Error fetching user balance:', error)
    }
  },

  async registerForAChallenge({ commit, rootGetters }, sending_data) {
    try {
      //const userInfo = rootGetters['auth/userInfo']
      const authToken = rootGetters['auth/token']
      
      // Call your API for deposit with the token in the headers
      const response = await fetch(`${process.env.VUE_APP_BASE_SERVICE_URL_LOCAL}/api/user/joinachallenge`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        },
        body: JSON.stringify(sending_data)
      })

      ////console.log('response is here')

      if (response.ok) {
        ////console.log('response is here')
        const data = await response.json()

        // Check if the response contains a new token
        if (data.token) {
          //authToken = data.token
          // Update the authToken in the auth module state
          commit('auth/setToken', data.token, { root: true })
        }

        //commit('setUserBalance', data.balance)

        // Check if the response contains a new token
        return data
      } else {
        // Handle error response
        console.error('Error:', response.statusText)

        const result = await response.json()

        if (response.status === 401) {

          // Authorization error, redirect to the authorization failed page
          commit('auth/setLoggedIn', false, { root: true })
          commit('auth/setUserInfo', {}, { root: true }) 
          commit('auth/setToken', '', { root: true }) 

          // Clear localStorage
          localStorage.removeItem('authToken') 
          localStorage.removeItem('userInfo')
          localStorage.removeItem('loggedIn')

          router.push('/authorization-failed')
          
        } else {
          // Redirect to a different route (e.g., login page)
          //router.push('/')
          return result
        }
      }
    } catch (error) {
      console.error('Error fetching user balance:', error)
    }
  },
  
  async checkEventDetail({ commit, rootGetters }, sending_data) {
    try {
      //const userInfo = rootGetters['auth/userInfo']
      const authToken = rootGetters['auth/token']
      
      // Call your API for deposit with the token in the headers
      const response = await fetch(`${process.env.VUE_APP_BASE_SERVICE_URL_LOCAL}/api/user/eventdetail`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        },
        body: JSON.stringify(sending_data)
      })

      ////console.log('response is here')

      if (response.ok) {
        ////console.log('response is here')
        const data = await response.json()

        // Check if the response contains a new token
        if (data.token) {
          //authToken = data.token
          // Update the authToken in the auth module state
          commit('auth/setToken', data.token, { root: true })
        }

        //commit('setUserBalance', data.balance)

        // Check if the response contains a new token
        return data
      } else {
        // Handle error response
        console.error('Error:', response.statusText)

        const result = await response.json()

        if (response.status === 401) {

          // Authorization error, redirect to the authorization failed page
          commit('auth/setLoggedIn', false, { root: true })
          commit('auth/setUserInfo', {}, { root: true }) 
          commit('auth/setToken', '', { root: true }) 

          // Clear localStorage
          localStorage.removeItem('authToken') 
          localStorage.removeItem('userInfo')
          localStorage.removeItem('loggedIn')

          router.push('/authorization-failed')
          
        } else {
          // Redirect to a different route (e.g., login page)
          //router.push('/')
          return result
        }
      }
    } catch (error) {
      console.error('Error fetching user balance:', error)
    }
  },
  
  async checkEvents({ commit, rootGetters }, sending_data) {
    try {
      //const userInfo = rootGetters['auth/userInfo']
      const authToken = rootGetters['auth/token']

      ////console.log('our auth is ', authToken)
      
      // Call your API for deposit with the token in the headers
      const response = await fetch(`${process.env.VUE_APP_BASE_SERVICE_URL_LOCAL}/api/user/events`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        },
        body: JSON.stringify(sending_data)
      })

      ////console.log('response is here')

      if (response.ok) {
        ////console.log('response is here')
        const data = await response.json()

        // Check if the response contains a new token
        if (data.token) {
          //authToken = data.token
          // Update the authToken in the auth module state
          commit('auth/setToken', data.token, { root: true })
        }

        //commit('setUserBalance', data.balance)

        // Check if the response contains a new token
        return data
      } else {
        // Handle error response
        console.error('Error:', response.statusText)

        if (response.status === 401) {

          // Authorization error, redirect to the authorization failed page
          commit('auth/setLoggedIn', false, { root: true })
          commit('auth/setUserInfo', {}, { root: true }) 
          commit('auth/setToken', '', { root: true }) 

          // Clear localStorage
          localStorage.removeItem('authToken') 
          localStorage.removeItem('userInfo')
          localStorage.removeItem('loggedIn')

          router.push('/authorization-failed')
          
        } else {
          // Redirect to a different route (e.g., login page)
          router.push('/')
        }
      }
    } catch (error) {
      console.error('Error fetching user balance:', error)
    }
  },

  async adminGetUsers({ commit, rootGetters }) {
    try {
      //const userInfo = rootGetters['auth/userInfo']
      const authToken = rootGetters['auth/token']
      
      // Call your API for deposit with the token in the headers
      //const response = await fetch(`${process.env.VUE_APP_BASE_SERVICE_URL_LOCAL}/api/user/myevents`, {
      const response = await fetch(`${process.env.VUE_APP_BASE_SERVICE_URL_LOCAL}/api/user/getlballu`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        }
        //body: JSON.stringify(sending_data)
      })

      ////console.log('response is here')

      if (response.ok) {
        ////console.log('response is here')
        const data = await response.json()

        // Check if the response contains a new token
        if (data.token) {
          //authToken = data.token
          // Update the authToken in the auth module state
          commit('auth/setToken', data.token, { root: true })
        }

        //commit('setUserBalance', data.balance)

        // Check if the response contains a new token
        return data
      } else {
        // Handle error response
        console.error('Error:', response.statusText)

        if (response.status === 401) {

          // Authorization error, redirect to the authorization failed page
          commit('auth/setLoggedIn', false, { root: true })
          commit('auth/setUserInfo', {}, { root: true }) 
          commit('auth/setToken', '', { root: true }) 

          // Clear localStorage
          localStorage.removeItem('authToken') 
          localStorage.removeItem('userInfo')
          localStorage.removeItem('loggedIn')

          router.push('/authorization-failed')
          
        } else {
          // Redirect to a different route (e.g., login page)
          router.push('/')
        }
      }
    } catch (error) {
      console.error('Error fetching user balance:', error)
    }
  },

  async checkMyEvents({ commit, rootGetters }, sending_data) {
    try {
      //const userInfo = rootGetters['auth/userInfo']
      const authToken = rootGetters['auth/token']
      
      // Call your API for deposit with the token in the headers
      const response = await fetch(`${process.env.VUE_APP_BASE_SERVICE_URL_LOCAL}/api/user/myevents`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        },
        body: JSON.stringify(sending_data)
      })

      ////console.log('response is here')

      if (response.ok) {
        ////console.log('response is here')
        const data = await response.json()

        // Check if the response contains a new token
        if (data.token) {
          //authToken = data.token
          // Update the authToken in the auth module state
          commit('auth/setToken', data.token, { root: true })
        }

        //commit('setUserBalance', data.balance)

        // Check if the response contains a new token
        return data
      } else {
        // Handle error response
        console.error('Error:', response.statusText)

        if (response.status === 401) {

          // Authorization error, redirect to the authorization failed page
          commit('auth/setLoggedIn', false, { root: true })
          commit('auth/setUserInfo', {}, { root: true }) 
          commit('auth/setToken', '', { root: true }) 

          // Clear localStorage
          localStorage.removeItem('authToken') 
          localStorage.removeItem('userInfo')
          localStorage.removeItem('loggedIn')

          router.push('/authorization-failed')
          
        } else {
          // Redirect to a different route (e.g., login page)
          router.push('/')
        }
      }
    } catch (error) {
      console.error('Error fetching user balance:', error)
    }
  },

  async fetchUserBalance({ commit, rootGetters }) {
    try {
      // Get the token from the auth module state
      let authToken = rootGetters['auth/token']

      // Make a request to fetch the user's balance from the backend with Authorization header
      const response = await fetch(`${process.env.VUE_APP_BASE_SERVICE_URL_LOCAL}/api/user/balance`, {
        method: 'GET',
        credentials: 'include', // Include cookies in the request
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}` // Include the Authorization token
        }
      })

      ////console.log('response is here')

      if (response.ok) {
        ////console.log('response is here')
        const data = await response.json()

        // Check if the response contains a new token
        if (data.token) {
          authToken = data.token
          // Update the authToken in the auth module state
          commit('auth/setToken', authToken, { root: true })
        }

        commit('setUserBalance', data.balance)
      } else {
        // Handle error response
        console.error('Error:', response.statusText)

        if (response.status === 401) {
          // Authorization error, redirect to the authorization failed page
          commit('auth/setLoggedIn', false, { root: true })
          commit('auth/setUserInfo', {}, { root: true }) 
          commit('auth/setToken', '', { root: true }) 

          // Clear localStorage
          localStorage.removeItem('authToken') 
          localStorage.removeItem('userInfo')
          localStorage.removeItem('loggedIn')

          router.push('/authorization-failed')
        } else {
          // Redirect to a different route (e.g., login page)
          router.push('/')
        }
      }
    } catch (error) {
      console.error('Error fetching user balance:', error)
    }
  },

  async withdrawalRequest ({ commit, rootGetters }, sending_data) {
    try {
      //const userInfo = rootGetters['auth/userInfo']
      const authToken = rootGetters['auth/token']
      
      // Call your API for deposit with the token in the headers
      const response = await fetch(`${process.env.VUE_APP_BASE_SERVICE_URL_LOCAL}/api/user/withdraw`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        },
        body: JSON.stringify(sending_data)
      })

      if (response.ok) {
        const data = await response.json()
        
        // Check if the response contains a new token
        return data
      } else {
        // Handle error response
        //console.error('Error OMO:', response.statusText)
        const data = await response.json()
        
        if (response.status === 401) {
          // Authorization error, redirect to the authorization failed page
          commit('auth/setLoggedIn', false, { root: true })
          commit('auth/setUserInfo', {}, { root: true }) 
          commit('auth/setToken', '', { root: true }) 

          // Clear localStorage
          localStorage.removeItem('authToken') 
          localStorage.removeItem('userInfo')
          localStorage.removeItem('loggedIn')

          router.push('/authorization-failed')
        } else {
          // Redirect to a different route (e.g., login page)
          //router.push('/')
          // Check if the response contains a new token
          return data
        }

        //return
      }
    } catch (error) {
      console.error('Error Verifying account details:', error)
      // Handle the error
    }
  },

  async verifyAccountNumber({ commit, rootGetters }, credentials) {
    try {
      //const userInfo = rootGetters['auth/userInfo']
      const authToken = rootGetters['auth/token']
      
      // Call your API for deposit with the token in the headers
      const response = await fetch(`${process.env.VUE_APP_BASE_SERVICE_URL_LOCAL}/api/user/verifyaccountdetails`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        },
        body: JSON.stringify(credentials)
      })

      if (response.ok) {
        const data = await response.json()
        
        // Check if the response contains a new token
        return data
      } else {
        // Handle error response
        //console.error('Error OMO:', response.statusText)

        if (response.status === 401) {
          // Authorization error, redirect to the authorization failed page
          commit('auth/setLoggedIn', false, { root: true })
          commit('auth/setUserInfo', {}, { root: true }) 
          commit('auth/setToken', '', { root: true }) 

          // Clear localStorage
          localStorage.removeItem('authToken') 
          localStorage.removeItem('userInfo')
          localStorage.removeItem('loggedIn')

          router.push('/authorization-failed')
        } else {
          // Redirect to a different route (e.g., login page)
          router.push('/')
        }

        //return
      }
    } catch (error) {
      console.error('Error Verifying account details:', error)
      // Handle the error
    }

  },
  async fetchTransferFee({ commit, rootGetters }, sending_data) {
    try {
      //const userInfo = rootGetters['auth/userInfo']
      let authToken = rootGetters['auth/token']

      // Make a request to fetch the user's balance from the backend with Authorization header
      const response = await fetch(`${process.env.VUE_APP_BASE_SERVICE_URL_LOCAL}/api/user/transferfee`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        },
        body: JSON.stringify(sending_data)
      })

      if (response.ok) {
        const data = await response.json()
        
        ////console.log('response is here', data)

        // Check if the response contains a new token
        if (data.token) {
          authToken = data.token
          // Update the authToken in the auth module state
          commit('auth/setToken', authToken, { root: true })
        }

        return data
      } else {
        // Handle error response
        console.error('Error OMO:', response.statusText)

        if (response.status === 401) {

          commit('auth/setLoggedIn', false, { root: true })
          commit('auth/setUserInfo', {}, { root: true }) 
          commit('auth/setToken', '', { root: true })

          // Clear localStorage
          localStorage.removeItem('authToken') 
          localStorage.removeItem('userInfo')
          localStorage.removeItem('loggedIn')

          router.push('/authorization-failed')
          // Authorization error, redirect to the authorization failed page
          
        } else {
          // Redirect to a different route (e.g., login page)
          router.push('/')
        }

        //return
      }
    } catch (error) {
      console.error('Error fetching country banks:', error)
      // Handle the error
    }

  },
  async fetchCountryBanks({ commit, rootGetters }) {
    ////console.log('WE DON LAND')
    try {
      //const userInfo = rootGetters['auth/userInfo']
      let authToken = rootGetters['auth/token']

      // Make a request to fetch the user's balance from the backend with Authorization header
      const response = await fetch(`${process.env.VUE_APP_BASE_SERVICE_URL_LOCAL}/api/user/banks`, {
        method: 'GET',
        //credentials: 'include', // Include cookies in the request
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}` // Include the Authorization token
        }
      })

      if (response.ok) {
        const data = await response.json()
        
        ////console.log('response is here', data)

        // Check if the response contains a new token
        if (data.token) {
          authToken = data.token
          // Update the authToken in the auth module state
          commit('auth/setToken', authToken, { root: true })
        }

        return data
      } else {
        // Handle error response
        console.error('Error OMO:', response.statusText)

        if (response.status === 401) {

          commit('auth/setLoggedIn', false, { root: true })
          commit('auth/setUserInfo', {}, { root: true }) 
          commit('auth/setToken', '', { root: true })

          // Clear localStorage
          localStorage.removeItem('authToken') 
          localStorage.removeItem('userInfo')
          localStorage.removeItem('loggedIn')

          router.push('/authorization-failed')
          // Authorization error, redirect to the authorization failed page
          
        } else {
          // Redirect to a different route (e.g., login page)
          router.push('/')
        }

        //return
      }
    } catch (error) {
      console.error('Error fetching country banks:', error)
      // Handle the error
    }

  },

  async submitEvent({ commit, rootGetters }, sending_data) {
    try {
      // Get the authentication token from the store
      let authToken = rootGetters['auth/token']
      const userInfo = rootGetters['auth/userInfo']

      // Call your API for deposit with the token in the headers
      const response = await fetch(`${process.env.VUE_APP_BASE_SERVICE_URL_LOCAL}/api/user/createEvent`, {
        method: 'POST',
        credentials: 'include', // Include cookies in the request
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        },
        body: JSON.stringify(sending_data)
      })

      if (response.ok) {
        const result = await response.json()
        // Assuming the API returns a success flag
        /* const balance = result.balance
        const token = result.token
        const userInfo = result.userInfo */
        
        if (result.token) {

          authToken = result.token

          // Update the authToken in the auth module state
          commit('auth/setToken', authToken, { root: true })
          commit('auth/setUserInfo', userInfo, { root: true })

          if ( result.token) {
          //
          }
        }

        return result

      } else {
        const result = await response.json()

        console.error('Error:', response.statusText)
  
        if (response.status === 401) {
          // Authorization error, redirect to the authorization failed page
          commit('auth/setLoggedIn', false, { root: true })
          commit('auth/setUserInfo', {}, { root: true }) 
          commit('auth/setToken', '', { root: true }) 

          // Clear localStorage
          localStorage.removeItem('authToken') 
          localStorage.removeItem('userInfo')
          localStorage.removeItem('loggedIn')
          
          // Clear localStorage
          localStorage.removeItem('authToken') 
          localStorage.removeItem('userInfo') 
          
          router.push('/authorization-failed')

        }

        return result

      }
      
    } catch (error) {
      console.error('Error submitting deposit:', error)
      // Handle the error
    }
  },
  
  async makeAttempt({ commit, rootGetters }, sending_data) {
    try {
      // Get the authentication token from the store
      let authToken = rootGetters['auth/token']
      const userInfo = rootGetters['auth/userInfo']

      // Call your API for deposit with the token in the headers
      const response = await fetch(`${process.env.VUE_APP_BASE_SERVICE_URL_LOCAL}/api/user/makeAttempt`, {
        method: 'POST',
        credentials: 'include', // Include cookies in the request
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        },
        body: JSON.stringify(sending_data)
      })

      if (response.ok) {
        const result = await response.json()
        // Assuming the API returns a success flag
        /* const balance = result.balance
        const token = result.token
        const userInfo = result.userInfo */
        
        if (result.token) {

          authToken = result.token

          // Update the authToken in the auth module state
          commit('auth/setToken', authToken, { root: true })
          commit('auth/setUserInfo', userInfo, { root: true })

          if ( result.token) {
          //
          }
        }

        return result
      } else {
        console.error('Error:', response.statusText)
  
        if (response.status === 401) {
          // Authorization error, redirect to the authorization failed page
          commit('auth/setLoggedIn', false, { root: true })
          commit('auth/setUserInfo', {}, { root: true }) 
          commit('auth/setToken', '', { root: true }) 

          // Clear localStorage
          localStorage.removeItem('authToken') 
          localStorage.removeItem('userInfo')
          localStorage.removeItem('loggedIn')
          
          // Clear localStorage
          localStorage.removeItem('authToken') 
          localStorage.removeItem('userInfo') 
          
          router.push('/authorization-failed')

        } else {
          // Redirect to a different route (e.g., login page)
          //router.push('/')
          return await response.json()
        }
      }
      
    } catch (error) {
      console.error('Error submitting deposit:', error)
      // Handle the error
    }
  },

  async submitGiving({ commit, rootGetters }, sending_data) {
    try {
      // Get the authentication token from the store
      let authToken = rootGetters['auth/token']
      const userInfo = rootGetters['auth/userInfo']

      // Call your API for deposit with the token in the headers
      const response = await fetch(`${process.env.VUE_APP_BASE_SERVICE_URL_LOCAL}/api/user/givetochallenge`, {
        method: 'POST',
        credentials: 'include', // Include cookies in the request
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        },
        body: JSON.stringify(sending_data)
      })

      if (response.ok) {
        const result = await response.json()
        // Assuming the API returns a success flag
        /* const balance = result.balance
        const token = result.token
        const userInfo = result.userInfo */
        
        if (result.token) {

          authToken = result.token

          // Update the authToken in the auth module state
          commit('auth/setToken', authToken, { root: true })
          commit('auth/setUserInfo', userInfo, { root: true })

          if ( result.token) {
          //
          }
        }

        return result
      } else {
        console.error('Error:', response.statusText)
  
        if (response.status === 401) {
          // Authorization error, redirect to the authorization failed page
          commit('auth/setLoggedIn', false, { root: true })
          commit('auth/setUserInfo', {}, { root: true }) 
          commit('auth/setToken', '', { root: true }) 

          // Clear localStorage
          localStorage.removeItem('authToken') 
          localStorage.removeItem('userInfo')
          localStorage.removeItem('loggedIn')
          
          // Clear localStorage
          localStorage.removeItem('authToken') 
          localStorage.removeItem('userInfo') 
          
          router.push('/authorization-failed')

        } else {
          // Redirect to a different route (e.g., login page)
          //router.push('/')
          return await response.json()
        }
      }
      
    } catch (error) {
      console.error('Error submitting deposit:', error)
      // Handle the error
    }
  },
  async submitDeposit({ commit, rootGetters }, sending_data) {
    try {
      // Get the authentication token from the store
      let authToken = rootGetters['auth/token']
      const userInfo = rootGetters['auth/userInfo']

      // Call your API for deposit with the token in the headers
      const response = await fetch(`${process.env.VUE_APP_BASE_SERVICE_URL_LOCAL}/api/user/deposit`, {
        method: 'POST',
        credentials: 'include', // Include cookies in the request
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        },
        body: JSON.stringify(sending_data)
      })

      if (response.ok) {
        const result = await response.json()
        // Assuming the API returns a success flag
        /* const balance = result.balance
        const token = result.token
        const userInfo = result.userInfo */
        
        if (result.token) {

          authToken = result.token

          // Update the authToken in the auth module state
          commit('auth/setToken', authToken, { root: true })
          commit('auth/setUserInfo', userInfo, { root: true })

          if ( result.token) {
          //
          }
        }

        return result
      } else {
        console.error('Error:', response.statusText)
        const result = await response.json()
  
        if (response.status === 401) {
          // Authorization error, redirect to the authorization failed page
          commit('auth/setLoggedIn', false, { root: true })
          commit('auth/setUserInfo', {}, { root: true }) 
          commit('auth/setToken', '', { root: true }) 

          // Clear localStorage
          localStorage.removeItem('authToken') 
          localStorage.removeItem('userInfo')
          localStorage.removeItem('loggedIn')
          
          // Clear localStorage
          localStorage.removeItem('authToken') 
          localStorage.removeItem('userInfo') 
          
          router.push('/authorization-failed')

        } else {
          // Redirect to a different route (e.g., login page)
          //router.push('/')
          return result
        }
      }
      
    } catch (error) {
      console.error('Error submitting deposit:', error)
      // Handle the error
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}  