<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="users"
          item-key="id"
          class="elevation-1"
        >
          <template v-slot:[`item.email`]="{ item }">
            <v-edit-dialog :return-value.sync="item.email" large @save="saveEmail(item)">
              <div>{{ item.email }}</div>
              <template v-slot:input>
                <v-text-field v-model="item.email" label="Email" single-line></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      users: [],
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Name', value: 'name' },
        { text: 'Email', value: 'email', sortable: false }
      ]
    }
  },
  mounted() {
    this.fetchUsers()
  },
  methods: {
    async fetchUsers() {
      // Assuming you have an API endpoint to fetch users
      try {
        if (this.$store.getters['auth/isLoggedIn']) {
          const response = await this.$store.dispatch('wallet/adminGetUsers')

          if (response && response.success) {
            if (response.success) {
              this.users = data
            }
          }
        } else {
          this.$router.push('/login')
        }
      } catch (error) {
        
        ////console.log(error)

      } finally {
        this.$store.commit('loading/setLoading', false, { root: true })
        //this.submitting = false
      }
    },
    saveEmail(user) {
      // Assuming you have an API endpoint to update user email
      fetch(`https://technologyofgod.com/api/users/${user.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email: user.email })
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Failed to update email')
          }
        })
        .catch((error) => {
          console.error('Error updating email:', error)
        })
    }
  }
}
</script>
